<template>
    <div class="posts">
        <template v-if="posts">
            <div class="row">
                <div v-for="(post, index) in posts" :key="`cs-post-${index}`"  v-if="index<4 || !limit" :class="displayClasses">
                    <div class="cs-pc-card-spacer">
                        <a :href="post.permalink" class="cs-pc-link">
                            <div class="cs-pc-card">
                                <div class="cs-image-container cs-image-ratio-16x9">
                                    <img :src="post.image" loading="lazy" :alt="post.title" />
                                </div>
                                <div class="cs-pc-info">
                                    <h4 class="cs-pc-title">
                                        <ShortText :data="post.title" :max="100" /> 
                                    </h4>
                                    <div class="cs-pc-text">
                                        <ShortText :data="post.text" :max="200"/> 
                                        <span class="cs-pc-permalink">
                                            {{ readMoreLabel }}
                                        </span>
                                    </div>
                                    <div class="cs-pc-tags">
                                        <span class="cs-pc-tag" v-for="(tag, tindex) in post.tags" :key="`${index}-${tindex}`" v-if="tindex < 4">
                                            {{tag.title}}
                                        </span>
                                    </div>
                                    <div class="cs-pc-author">
                                        <div class="cs-pc-user">{{ post.author }}</div>
                                        <div class="cs-pc-date">
                                            <Time :data="post.updated"/> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="!limit" class="pagination">
                <span class="left-arrow" v-if="page>1"  @click="changePage(-1)">
                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                        <path d="M20 .755l-14.374 11.245 14.374 11.219-.619.781-15.381-12 15.391-12 .609.755z"/>
                    </svg>
                </span>
                <span>{{page}}</span>
                <span class="right-arrow" v-if="Object.keys(posts).length>20" @click="changePage(1)">
                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                        <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z"/>
                    </svg>
                </span>
            </div>
        </template>
    </div>
</template>

<script>
    const getUrlParamter = () => {
        let url = window.location.search.split('?');
        let out = {};
        if(url.length>1){
            let parameters = url[1].split('&')
            for (var i = 0; i < parameters.length; i++) {
                let tmp = parameters[i].split('=')
                if(tmp.length==1) out[tmp[0]] = true;
                else{
                    out[tmp[0]] = tmp[1];
                }
            }
        }
        return out
    }
    const insertParam = (key, value, location) => {
        key = encodeURIComponent(key);
        value = encodeURIComponent(value);

        var kvp = window.location.search.substr(1).split('&');
        let i=0;

        for(; i<kvp.length; i++){
            if (kvp[i].startsWith(key + '=')) {
                let pair = kvp[i].split('=');
                pair[1] = value;
                kvp[i] = pair.join('=');
                break;
            }
        }

        if(i >= kvp.length){
            kvp[kvp.length] = [key,value].join('=');
        }

        // can return this or...
        let params = kvp.join('&');

        // reload page with new params
        window.location.search = params;
    }

    export default {
        name: "CSUtilsPostCards",
        components: {
            ShortText: () => import("./CSUtilsShortText.vue"),
            Time: () => import("./CSUtilsTime.vue")
        },
        data(){
            return {
                page:0
            }
        },
        props: {
            posts: {
                type: [Object, Array],
                default: () =>{
                    return {}
                }
            },
                readMoreLabel:{
                type: String,
                default:''
            },
            limit: {
                type: Boolean,
                default: false
            },
        },
        methods:{
            changePage(a){
                try{
                    if(typeof window != 'undefined'){
                        if(this.page > 1 && a == -1){
                            this.page = this.page -1
                            insertParam('page',this.page)
                        }else if(a == 1){
                            this.page = this.page + 1
                            insertParam('page',this.page)
                        }
                    }
                }catch(e){
                    console.log(e)
                }
            }
        },
        computed: {
            displayClasses(){
                let out = '';
                let postsLength = this.posts && typeof this.posts == 'object' ? Object.keys(this.posts).length : 0;

                if(postsLength > 0){
                    if(postsLength > 3) out += 'col-lg-4 col-xl-3';
                    else if(postsLength == 3) out+= 'col-md-6 col-lg-4';
                    else if(postsLength == 2) out += 'col-md-6';
                    else if(postsLength == 1) out+= 'col';

                    if(this.limit) out+= ' cs-post-limit';
                }

                return out
            },
        },
        mounted() {
            if (!this.limit) {
                try{
                    console.log(typeof window)
                    if(typeof window !== 'undefined'){
                        const urlParams = getUrlParamter(window.location.search);
                        const page = urlParams['page']
                        if(!page) {
                            insertParam('page','1')
                            this.page = 1
                        }else{
                            this.page = parseInt(page)
                        }
                    }
                }catch(e){
                    console.log(e)
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @media (max-width:767px){
        .row .cs-post-limit:nth-child(2), .row .cs-post-limit:nth-child(3) {
            display:none;
        }
    }
    @media (max-width:991px){
        .row .cs-post-limit:nth-child(3) {
            display:none;
        }
    }
    .cs-pc-card-spacer {
        height: 100%;
        padding-bottom: 30px;

        a.cs-pc-link {
            height: 100%;
            display: block;
            display: flex;
            border-radius: 15px;
            overflow: hidden;
            border: 1px solid #ddd;
            background: white;
            -webkit-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.2);
            -moz-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.2);
            box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.2);
            color: inherit;
            text-decoration: none;
            .cs-pc-card{
                width: 100%;
                display: flex;
                flex-direction: column;

                .cs-pc-info {
                    padding: 10px;
                    flex: 1 1 auto;
                    display: flex;
                    flex-direction: column;

                    h4.cs-pc-title {
                        margin-bottom: 20px;
                    }
                    .cs-pc-text {
                        font-size: 1rem;
                        font-weight: 300;
                        margin-bottom: 30px;
                        span.cs-pc-permalink {
                            color: blue;
                        }
                    }
                    .cs-pc-tags{
                        min-height: 66px;
                        overflow:hidden;
                        margin-bottom: 15px;

                        span.cs-pc-tag {
                            background: #ddd;
                            padding: 2px 5px;
                            border-radius: 25px;
                            margin-right: 15px;
                            display: inline-block;
                            margin-bottom: 5px;
                        }
                    }
                    .cs-pc-author {
                        margin-top: auto;
                        padding-top: 10px;
                        display: flex;
                        border-top: 1px solid #ddd;
                        .cs-pc-user {
                            font-weight: 600;
                            letter-spacing: 0.5px;
                            color: #7b7b7b;
                        }
                        .cs-pc-date {
                            color: #7b7b7b;
                        }
                    }
                }
            }

        }
    }
    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            padding: 10px;
        }
        span.right-arrow {
            padding: 10px 8px 10px 12px;
        }
        
        span.left-arrow {
            padding: 10px 12px 10px 8px;
        }
        span.right-arrow, span.left-arrow {
            background: #ddd;
            border-radius: 50%;
        }
    }

</style>
